@tailwind components;
@tailwind utilities;

 

.policy-list {
  background: #FFF; 
  font-size: 20px;
  min-width: 15em;

  margin: auto;
  padding: 0.5em 1em;
   
}

.policy {
  display: block;
  position: relative;
  padding: 1em 1em 1em 4%;
  margin: 0 auto;

  /* cursor: pointer;  */
  border-bottom: solid 1px #ddd;
   
}

.todo__state {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
}

.policy__text {
  color: saturate(#1B4A4E,15%); 
}

.policy__icon {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: auto;
  margin: auto;
  
  fill: none;
  stroke: #27FDC7;
  stroke-width: 2;
  stroke-linejoin: round;
  stroke-linecap: round;
}


.policy__line,
.policy__box,
 

.policy__text{
  float:left
}
.policy__price{
  float:right
}

.policy__header{
  text-align: center; 
}

.policy__type__text{
  text-align: center;
  opacity: .75;
  font-size: 80%;
  padding-top: 37px;
  font-weight: bold;
  border-bottom: solid 3px black;
}

.policy__offer{
  display: flow-root;
  overflow-wrap: anywhere;
}
.policy__question{
  font-size: 75%;
}

 

.policy__box { 
  stroke-dasharray: 56.1053, 56.1053; stroke-dashoffset: 0;
 
}
.policy__check {
  stroke: #27FDC7;
  stroke-dasharray: 9.8995, 9.8995; stroke-dashoffset: 9.8995;
 
}
.policy__line {
  stroke-dasharray: 168, 1684; 
  stroke-dashoffset: 168;
} 
 